<script setup>
import { ref } from 'vue';

const props = defineProps({
    message: String
})
const status = ref(false);

function toggleStatus(action) {
    if (action === 'toggle') {
        if (status.value === true) {
            status.value = false
        } else {
            status.value = true
        }
    }
    if (action === 'close') {
        status.value = false
    }
    if (action === 'open') {
        status.value = true
    }
}
</script>
<template>
    <div  v-on:mouseleave="toggleStatus('close')">
        <i class="fa fa-circle-info text-[14px] text-slate-700 cursor-pointer hover:text-gr-green" :class="{'!text-gr-green' : status}" v-on:click="toggleStatus('toggle')" v-on:mouseenter="toggleStatus('open')"></i>
        <div class="absolute bg-white border shadow-lg rounded p-2 text-[12px] z-10 w-80" v-if="status">{{ props.message }}</div>
    </div>
</template>