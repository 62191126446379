<script setup>
import { ref, onMounted, onUnmounted, watch} from 'vue';
import { useAuthStore } from '../stores/auth';
import { useDealerStore } from '../stores/dealer';
import { useVehiclesStore} from '../stores/vehicles'
import { getVehicle } from '../services/vehicle_apis';
import { useSystemStore } from '../stores/system';
import axios from "axios";
import ImageSlider from '../components/Vehicles/ImageSlider.vue'
import {generateWindowSticker} from '../services/window_sticker'

const authStore = useAuthStore();
const dealerStore = useDealerStore();
const vehiclesStore = useVehiclesStore();
const systemStore = useSystemStore();
const searchStatus = ref(false);
const searchKeyword = ref('');
const filteredList = ref([]);

function search () {
    if (searchKeyword.value.length > 3) {
        searchStatus.value = true;
        filteredList.value = vehiclesStore.vehicles.filter((vehicle) => {
            const vehicle_title = vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model;
            if (vehicle.vin.toLowerCase().includes(searchKeyword.value.toLowerCase()) || vehicle_title.toLowerCase().includes(searchKeyword.value.toLowerCase())) {
                return vehicle
            }
        })
    } else {
        searchStatus.value = false;
    }

}
const convertToFloat = (str) => {
    let num = parseFloat(str);
    if (isNaN(num)) {
        return 0;
    }
    // Round to 2 decimal places
    num = Math.round(num * 100) / 100;
    
    // Add commas to the number
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

const vehicles = ref([])

const convertStatus = (status) => {
    const labels = {
        "in_stock" : "In-Stock",
        "sold" : "Sold",
        "available" : "Available",
        "pending" : "Pending",
        "delivered" : "Delivered"
    }
    return labels[status];
}
onMounted(() => {
    vehiclesStore.getVehicles();
    // const configs = {
    //         headers: {
    //             "x-auth-token" : authStore.getAuthToken()
    //         }
    //     }
    // axios.get(apiEndpoint + '/' + dealerStore.getDealerId() + '/vehicles/summary', configs)
    // .then((response) => {
    //     if (response.status == 200) {
    //         vehicles.value = response.data.data.vehicles;
    //     }
    // })
})
onUnmounted(() => {
    vehiclesStore.resetVehicles();
})
watch(dealerStore.getDealerId, async (newValue,oldValue) => {
    try {
        vehiclesStore.getVehicles()
    } catch {
        console.log('error-Vehicles.vue')
    }
})

function openPreviewModal(vehicleId) {
    systemStore.enableLoadingStatus();
    loadVehicleData(vehicleId);
}

const preview_vehicle_data= ref();

const loadVehicleData = async (vehicle_id) => {
    preview_vehicle_data.value = await getVehicle(vehicle_id);
    preview_vehicle_data.value = preview_vehicle_data.value.data.data
    vehiclesStore.setVehicleModal(vehicle_id);
    systemStore.disableLoadingStatus();

};

function getDaysInStock(dateString) {
    // Create a Date object from the given string (in 'YYYY-MM-DD' format)
    const dateInStock = new Date(dateString);
    
    // Get today's date
    const today = new Date();
    
    // Calculate the difference in milliseconds
    const differenceInMs = today - dateInStock;
    
    // Convert the difference from milliseconds to days
    const daysInStock = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    
    return daysInStock;
}

const dateRange = ref(0);
function setDateRange() {
    const today = new Date(); // Get today's date
    today.setDate(today.getDate() - dateRange.value); // Subtract the days
    vehiclesStore.toggleInventoryAgeFilter(today.toISOString().split('T')[0]); // Return the date in YYYY-MM-DD format

    
}

</script>
<template>
    <h3 class="text-3xl font-bold mb-4">Vehicles Inventory</h3>
    <div class="my-2 flex flex-col md:flex-row justify-between justify-items-stretch sm:items-stretch gap-2 items-center">
        <div class="hidden md:flex flex-col md:flex-row gap-2">
            <div class="flex flex-row justify-items-stretch">
                <select class="rounded-md px-4 border" :class="{'outline outline-2 outline-gr-green' : vehiclesStore.filters.status !== 'all'}" v-model="vehiclesStore.filters.status" v-on:change="vehiclesStore.triggerFilteredSearch">
                    <option class="" disabled selected>Select Status</option>
                    <option :value="'in_stock'">In-Stock</option>
                    <option :value="'pending'">Pending Deal</option>
                    <option :value="'sold'">Sold</option>
                    <option :value="'delivered'">Delivered</option>
                    <option :value="'removed'">Removed</option>
                    <option :value="'all'">All Vehicles</option>
                </select>
            </div>
            <div class="flex justify-items-stretch">
                <select class="rounded-md px-4 border" :class="{'outline outline-2 outline-gr-green' : vehiclesStore.filters.date !== 0 }" v-on:change="setDateRange()" v-model="dateRange">
                    <option class="" disabled selected>Days In-Stock</option>
                    <option :value="0">All</option>
                    <option :value="15">15+ Days</option>
                    <option :value="30">30+ Days</option>
                    <option :value="60">60+ Days</option>
                    <option :value="90">90+ Days</option>
                    <option :value="120">120+ Days</option>
                </select>
            </div>
            <div class="flex justify-items-stretch">
                <div class="rounded-md px-4 border flex items-center bg-white cursor-pointer hover:bg-gray-50" :class="{'outline outline-2 outline-gr-green' : vehiclesStore.filters.missing_images}" v-on:click="vehiclesStore.toggleMissingImagesFilter()" title="Show vehicles that are missing images">Missing Images</div>
            </div>
            <div class="flex justify-items-stretch">
                <div class="rounded-md px-4 border flex items-center bg-white cursor-pointer hover:bg-gray-50"  :class="{'outline outline-2 outline-gr-green' : vehiclesStore.filters.missing_price}" v-on:click="vehiclesStore.toggleMissingPriceFilter()" title="Show vehicles that are missing pricing">No Price</div>
            </div>
        </div>
        <div class="flex flex-row justify-end">
            <div class="grid sm:px-2 items-stretch justify-items-stretch">
                <input type="text" v-model="searchKeyword" v-on:keyup="search()" class="rounded px-2 w-full sm:w-72 text-sm py-4" placeholder="Search by Stock #, Make, Model, VIN ...">
            </div>
            <router-link to="/vehicles/create" class="bg-gr-green px-4 py-2 rounded-md text-white flex items-center">Add a Vehicle</router-link>
        </div>
    </div>
    <div class="border rounded-t-md flex flex-col my-4">
        <div class="grid grid-cols-12 w-full bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">
            <div class="col-span-3 sm:col-span-1">Stock No.</div>
            <div class="col-span-4 sm:col-span-2">Vehicle</div>
            <div class="sm:col-span-2 hidden sm:block">VIN</div>
            <div class="sm:col-span-2 hidden sm:block">Mileage</div>
            <div class="sm:col-span-1 hidden sm:block">Status</div>
            <div class="hidden sm:block ">Price</div>
            <div class="col-span-2"></div>
        </div>
        <div v-if="vehiclesStore.vehicles.length === 0" class="grid grid-cols-12 items-center w-full px-4 py-8 sm:py-4 border-t text-sm bg-white border-l-gr-green border-l-2 cursor-pointer hover:bg-gr-grid-top">
                <div class="text-center col-span-12">Loading Data</div>
        </div>

        <div v-if="!searchStatus" v-for="vehicle in vehiclesStore.vehicles">
            <div  class="flex flex-row justify-between sm:grid sm:grid-cols-12 items-center w-full px-4 py-8 sm:py-4 border-t text-sm bg-white border-l-gr-green border-l-2 cursor-pointer hover:bg-gr-grid-top">
                <div class="col-span-3 sm:col-span-1 hidden sm:block">{{vehicle.stock_no}}</div>
                <div class="col-span-4 sm:col-span-2">{{vehicle.year}} {{vehicle.make}} {{vehicle.model}} <span class="block sm:hidden"><strong>Stock #:</strong> {{vehicle.stock_no}}</span></div>
                <div class="sm:col-span-2 hidden sm:block">{{vehicle.vin}}</div>
                <div class="sm:col-span-2 hidden sm:block">{{vehicle.mileage}} KM</div>
                <div class="sm:col-span-1 hidden sm:block"  :title="'In-stock since ' + vehicle.instock_date">{{convertStatus(vehicle.status)}} <span  v-if="vehicle.status === 'in_stock'" class="text-xs">({{ getDaysInStock(vehicle.instock_date) }} Days)</span></div>
                <div class="hidden sm:block">${{ convertToFloat(vehicle.price) }}</div>
                <div class="col-span-2 sm:col-span-3 flex gap-2 justify-end">
                    <button v-on:click="openPreviewModal(vehicle.vehicle_id)" class="border px-2 py-1.5 rounded-md border-gray-300 hover:bg-gr-green hover:text-white hover:border-gr-green">Preview</button>
                    <router-link v-if="vehicle.status !== 'sold'" :to="'/deals/create/?vehicle=' + vehicle.vehicle_id" class="border px-2 py-1.5 rounded-md border-gray-300 hover:bg-[#015e54] hover:text-white hover:border-[#015e54]"><i class="fa fa-dollar mr-1"></i>Create Deal</router-link>
                    <router-link :to="'/vehicles/' + vehicle.vehicle_id + '/edit'" class="border px-2 py-1.5 rounded-md border-gray-300 hover:bg-gr-green hover:text-white hover:border-gr-green">Edit</router-link>
                </div>
            </div>
        </div>

        <div v-if="searchStatus" v-for="vehicle in filteredList">
            <div  class="flex flex-row justify-between sm:grid sm:grid-cols-12 items-center w-full px-4 py-8 sm:py-4 border-t text-sm bg-white border-l-gr-green border-l-2 cursor-pointer hover:bg-gr-grid-top">
                <div class="col-span-3 sm:col-span-1 hidden sm:block">{{vehicle.stock_no}}</div>
                <div class="col-span-4 sm:col-span-2">{{vehicle.year}} {{vehicle.make}} {{vehicle.model}} <span class="block sm:hidden"><strong>Stock #:</strong> {{vehicle.stock_no}}</span></div>
                <div class="sm:col-span-2 hidden sm:block">{{vehicle.vin}}</div>
                <div class="sm:col-span-2 hidden sm:block">{{vehicle.mileage}} KM</div>
                <div class="sm:col-span-1 hidden sm:block"  :title="'In-stock since ' + vehicle.instock_date">{{convertStatus(vehicle.status)}} <span  v-if="vehicle.status === 'in_stock'" class="text-xs">({{ getDaysInStock(vehicle.instock_date) }} Days)</span></div>
                <div class="hidden sm:block">${{ convertToFloat(vehicle.price) }}</div>
                <div class="col-span-3 sm:col-span-3 flex gap-2 justify-end">
                    <button v-on:click="openPreviewModal(vehicle.vehicle_id)" class="border px-2 py-1.5 rounded-md border-gray-300 hover:bg-gr-green hover:text-white hover:border-gr-green">Preview</button>
                    <router-link :to="'/deals/create/?vehicle=' + vehicle.vehicle_id" class="border px-2 py-1.5 rounded-md border-gray-300 hover:bg-gr-green hover:text-white hover:border-gr-green">Create Deal</router-link>
                    <router-link :to="'/vehicles/' + vehicle.vehicle_id + '/edit'" class="border px-2 py-1.5 rounded-md border-gray-300 hover:bg-gr-green hover:text-white hover:border-gr-green">Edit</router-link>
                </div>
            </div>
        </div>

    </div>

    <Teleport to="body" v-if="vehiclesStore.vehicleModalStatus">
        <Transition name="fade">
            <div class="w-screen h-screen bg-black bg-opacity-60 fixed left-0 top-0 flex justify-center items-center max-h-screen z-41">
                <div class="bg-white sm:w-[70%] rounded py-8 px-2 sm:px-6 flex flex-col gap-2 mx-1 sm:mx-2 max-h-[95vh] my-12 overflow-y-scroll">
                    <h4 class="text-[24px] font-semibold">Stock #{{preview_vehicle_data.stock_number}} | {{preview_vehicle_data.year }} {{preview_vehicle_data.make }} {{preview_vehicle_data.model }}</h4>
                    <div class="flex flex-col-reverse sm:grid sm:grid-cols-2 ">
                        <div class="flex flex-col gap-2  text-[14px] sm:text-sm">
                            <div>
                                <button class="hover:bg-gray-100 hover:text-gr-green hover:border border border-gr-green bg-gr-green p-2 rounded text-white" v-on:click="generateWindowSticker(preview_vehicle_data, dealerStore.dealerData)">Generate Window Sticker</button>
                            </div>
                            <div class="flex">
                                <div class="font-semibold">VIN: </div>
                                <div class="ml-1">{{preview_vehicle_data.vin}}</div>
                            </div>
                            <div class="flex flex-col">
                                <div class="font-semibold">Make/Year/Model/Trim: </div>
                                <div class="sm:ml-1">{{preview_vehicle_data.year }} {{preview_vehicle_data.make }} {{preview_vehicle_data.model }} {{preview_vehicle_data.vehicle_specifications.trim }}</div>
                            </div>
                            <div class="flex">
                                <div class="font-semibold">Style: </div>
                                <div class="ml-1">{{preview_vehicle_data.vehicle_specifications.style }}</div>
                            </div>
                            <div class="flex">
                                <div class="font-semibold">Mileage: </div>
                                <div class="ml-1">{{preview_vehicle_data.mileage }} {{preview_vehicle_data.vehicle_settings.odometer_unit }}</div>
                            </div>
                            <div class="flex">
                                <div class="font-semibold">Body Type: </div>
                                <div class="ml-1">{{preview_vehicle_data.vehicle_specifications.body_type }}</div>
                            </div>
                            <div class="flex flex-col">
                                <div class="font-semibold">Engine/Transmission: </div>
                                <div class="sm:ml-1">{{preview_vehicle_data.vehicle_specifications.engine.size }}L {{preview_vehicle_data.vehicle_specifications.engine.cylinder }} Cylinders / {{preview_vehicle_data.vehicle_specifications.transmission.style }}</div>
                            </div>
                            <div class="flex">
                                <div class="font-semibold">Drivetrain: </div>
                                <div class="ml-1">{{preview_vehicle_data.vehicle_specifications.drivetrain.drive_type }}</div>
                            </div>
                            <div class="flex">
                                <div class="font-semibold">Exterior Color:</div>
                                <div class="ml-1" v-if="preview_vehicle_data.vehicle_settings.exterior_color">{{preview_vehicle_data.vehicle_settings.exterior_color['Generic Name'] ? capitalizeFirstLetter(preview_vehicle_data.vehicle_settings.exterior_color['Generic Name']) : capitalizeFirstLetter(preview_vehicle_data.vehicle_settings.exterior_color['generic_name']) }} - {{preview_vehicle_data.vehicle_settings.exterior_color['Color'] ? preview_vehicle_data.vehicle_settings.exterior_color['Color'] : preview_vehicle_data.vehicle_settings.exterior_color['description'] }} </div>
                            </div>
                            <div class="flex">
                                <div class="font-semibold">Interior Color:</div>
                                <div class="ml-1" v-if="preview_vehicle_data.vehicle_settings.interior_color">{{preview_vehicle_data.vehicle_settings.interior_color['Generic Name'] ? capitalizeFirstLetter(preview_vehicle_data.vehicle_settings.interior_color['Generic Name']) : capitalizeFirstLetter(preview_vehicle_data.vehicle_settings.interior_color['generic_name']) }} - {{preview_vehicle_data.vehicle_settings.interior_color['Color'] ? preview_vehicle_data.vehicle_settings.interior_color['Color'] : preview_vehicle_data.vehicle_settings.interior_color['description'] }}</div>
                            </div>
                            <div class="flex">
                                <div class="font-semibold">Certified:</div>
                                <div class="ml-1" v-if="preview_vehicle_data.vehicle_settings.certified"> Yes</div>
                                <div class="ml-1" v-if="!preview_vehicle_data.vehicle_settings.certified"> No</div>
                            </div>
                            <div class="flex">
                                <div class="font-semibold">MTO Brand:</div>
                                <div class="ml-1" v-if="preview_vehicle_data.vehicle_settings.mto_brand"> {{preview_vehicle_data.vehicle_settings.mto_brand}}</div>
                                <div class="ml-1" v-if="!preview_vehicle_data.vehicle_settings.mto_brand"> Unknown</div>
                            </div>
                            <div class="flex">
                                <div class="font-semibold">Daily Rental?:</div>
                                <div class="ml-1" v-if="preview_vehicle_data.vehicle_settings.daily_rental"> Yes</div>
                                <div class="ml-1" v-if="!preview_vehicle_data.vehicle_settings.daily_rental"> No</div>
                            </div>
                            <div class="flex flex-col gap-1">
                                <div class="font-semibold">Description:</div>
                                <div class=" text-xs pr-2" v-if="preview_vehicle_data.vehicle_description.text"> {{preview_vehicle_data.vehicle_description.text}}</div>
                            </div>
                        </div>
                        <div class="my-4 sm:m-0">
                            <div class="block">
                                <ImageSlider :images="preview_vehicle_data.vehicle_images" />
                            </div>
                        </div>
                    </div>
                    <div class="flex mt-4 gap-4">
                        <button class="bg-red-500 rounded text-white text-lg py-1.5 px-6"  v-on:click="vehiclesStore.resetVehicleModal()">Close</button>
                    </div>
                </div>
            </div>
        </Transition>
    </Teleport>


</template> 