<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useAuthStore } from '../stores/auth';
import { useDealerStore } from '../stores/dealer';
import InventoryHealthChart from '../components/ReportCharts/InventoryHealthChart.vue' 
import DealsThisMonthChart from '../components/ReportCharts/DealsThisMonthChart.vue' 
import InfoTooltip from '../components/InfoTooltip.vue';
import { useSystemStore } from '../stores/system';


const authStore = useAuthStore();
const dealerStore = useDealerStore();
const systemStore = useSystemStore();
onMounted(() => {

    if (dealerStore.getDealerData().id > 0) {
        systemStore.fetchReports(dealerStore.getDealerData().id, authStore.getAuthToken());

    }
    dealerStore.$subscribe((mutations, state) => {
    if (state.dealerData.id > 1) {
        systemStore.fetchReports(dealerStore.getDealerData().id, authStore.getAuthToken());
    }
})
    
})
onUnmounted(() => {
})


</script>
<template>

    <div class="grid xl:grid-cols-3 gap-2 mt-4">
        <!-- First Section -->
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">
                Inventory Overview</div>
            <div class="bg-white grid grid-cols-9 gap-2 px-4 py-4">
                <div class="col-span-9">
                    <InventoryHealthChart :dataset="systemStore.dashboardReports.inventoryHealthData"/>
                </div>
            </div>
        </div>
        <!-- First Section -->
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4 flex">
                Deals This Month <InfoTooltip class="normal-case ml-2" message="Sold & Delivered data are based on this months' dates. For example, if a deal was created last month and marked as sold this month, it will be reported below." /></div>
            <div class="bg-white grid grid-cols-9 gap-2 px-4 py-4">
                <div class="col-span-9">
                    <DealsThisMonthChart :dataset="systemStore.dashboardReports.dealsMTD"/>
                </div>
            </div>
        </div>
        <!-- First Section -->
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">
                Deals (Year To Date)</div>
            <div class="bg-white grid grid-cols-9 gap-2 px-4 py-4">
                <div class="col-span-9">
                    <DealsThisMonthChart :dataset="systemStore.dashboardReports.dealsYTD"/>
                </div>
            </div>
        </div>
        <!-- First Section -->
        <div class="border rounded-t-md hidden">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">
                Leads Overview</div>
            <div class="bg-white grid grid-cols-9 gap-2 px-4 py-4">
                <div class="col-span-9">
                </div>
            </div>
        </div>

    </div>
</template>