import { defineStore } from "pinia";
import { ref } from "vue";
import router from "../router";
import axios from "axios";

export const useSystemStore = defineStore('systemStore', () => {

    const closeModalStatus = ref(false);
    const closeModalUserStatus = ref(null);
    const continueToNavigate = ref(false);
    const navigationPath = ref(null);

    const loadingStatus = ref(false);

    const dashboardReports = ref({
        inventoryHealthData: {},
        dealsMTD : {},
        dealsYTD : {}
    });
    const openCloseModal = (to) => {
        closeModalStatus.value = true;
        navigationPath.value = to;

    }
    const continueNavigation = () => {
        closeModalStatus.value = false;
        closeModalUserStatus.value = true;
        router.push(navigationPath.value.path);
        navigationPath.value = null;
        setTimeout(() => {
            closeModalUserStatus.value = null;
        }, 1000);


    }
    const cancelNavigation = () => {
        closeModalStatus.value = false;
        closeModalUserStatus.value = null;
        navigationPath.value = null;
    }

    const disableLoadingStatus = () => {
        loadingStatus.value = false;
    }
    
    const enableLoadingStatus = () => {
        loadingStatus.value = true;
    }

    const byPassNavigationGuard = () => {
        continueToNavigate.value = true;
        closeModalUserStatus.value = true;

        setTimeout(() => {
            closeModalUserStatus.value = false;
        }, 2000)
    }

    const fetchReports = (dealerID, authToken) => {
        enableLoadingStatus();
        const configs = {
            headers: {
                "x-auth-token" : authToken
            }
        }
        const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

        axios.get(apiEndpoint + '/' + dealerID + '/reports', configs).then((response) => {
            dashboardReports.value.inventoryHealthData = response.data.data.inventory_health
            dashboardReports.value.dealsMTD = response.data.data.deals_mtd
            dashboardReports.value.dealsYTD = response.data.data.deals_ytd
            disableLoadingStatus();
        }). catch((error) => {
            disableLoadingStatus();

        })

    }

    return {
        closeModalStatus,
        closeModalUserStatus,
        continueToNavigate,
        navigationPath,
        continueNavigation,
        openCloseModal,
        cancelNavigation,
        loadingStatus,
        enableLoadingStatus,
        disableLoadingStatus,
        byPassNavigationGuard,
        dashboardReports,
        fetchReports
    }
})