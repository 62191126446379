'use strict';
import {useAuthStore} from '../stores/auth';
import { useDealerStore } from '../stores/dealer';
import axios from 'axios';

const generateWindowSticker = (vehicle, dealership)=> {

    const convertToFloat = (str) => {
        let num = parseFloat(str);
        if (isNaN(num)) {
            return 0;
        }
        // Round to 2 decimal places
        num = Math.round(num * 100) / 100;
        
        // Add commas to the number
        return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    
    }
    let image;
    if (vehicle.vehicle_images[0]) {
      image = vehicle.vehicle_images[0].link;
    } else {
      image = ''
    }
    let dealership_info = {
        address: {
            street_address: "",
            city: "",
            province: "",
            postal_code: ""
        },
        contact: {
            phone: "",
            website: ""
        },
        media: {
            logo: ""
        }
    }
    if (dealership.dealer_address.street_address != '') {
        dealership_info.address.street_address = dealership.dealer_address.street_address;
        dealership_info.address.city = dealership.dealer_address.city;
        dealership_info.address.province = dealership.dealer_address.province;
        dealership_info.address.postal_code = dealership.dealer_address.postal_code;
    }
    try {
        if (dealership.dealer_settings.contact.phone !== '') {
            dealership_info.contact.website = dealership.dealer_settings.contact.website;
            dealership_info.contact.phone = dealership.dealer_settings.contact.phone;
        }
        if (dealership.dealer_settings.media.logo !== '') {
            dealership_info.media.logo = dealership.dealer_settings.media.logo;
        }
    
    } catch (e) {
        console.log('error');
    }
    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Car Window Sticker</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
        }
        .container {
          width: 800px;
          margin: auto;
          padding: 10px;
          border: 1px solid #000;
          box-sizing: border-box;
        }
        .header {
          text-align: center;
          margin-bottom: 10px;
        }
        .header img {
          max-width: 150px;
          display: block;
          margin: 0 auto;
        }
        .dealership-info {
          margin-top: 10px;
        }
        .top-section {
          display: flex;
          margin-bottom: 5px;
        }
        .vehicle-image {
          width: 50%;
          text-align: center;
        }
        .vehicle-image img {
          max-width: 90%;
          height: auto;
          border: 1px solid #ddd;
          padding: 2px;
        }
        .car-info {
          width: 50%;
          padding-left: 10px;
        }
        .car-info h2, .car-info h5 {
          margin-top: 0;
          margin-bottom: 5px;
        }
        .car-info h3 {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .car-info p {
          margin: 5px 0;
          font-size: 16px;
        }
        .description {
          margin-top: 10px;
        }
        .description h3 {
          margin-bottom: 5px;
          border-bottom: 1px solid #000;
          padding-bottom: 5px;
        }
        .description p {
          margin-top: 0;
          font-size: 13px;
          line-height: 1.3;
        }
        @media print {
          body {
            height: 100%;
            width: 100%;
            display: table-row;

            margin: 0;
          }
          .container {
            border: 2px solid black;
    display: table-cell;
          }
          @page {
            margin: 0.3in;
          }
        }
      </style>
    </head>
    <body>
      <div class="container">
        <div class="header">
          <img src="${dealership_info.media.logo}" alt="Dealership Logo">
          <div class="dealership-info">
            <p><strong>${dealership.dealer_name}</strong></p>
            <p>${dealership_info.address.street_address}, ${dealership_info.address.city}, ${dealership_info.address.province} ${dealership_info.address.postal_code}</p>
            <p>Phone: <strong>${dealership_info.contact.phone}</strong> | Website: <strong>${dealership_info.contact.website}</strong></p>
          </div>
        </div>
        <div class="top-section">
          <div class="vehicle-image">
            <img src="${image}" style="aspect-ratio:4/3;object-fit:cover;" alt="Vehicle Image">
          </div>
          <div class="car-info">
            <h2>${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.vehicle_specifications.trim}</h2>
            <h5>${vehicle.vehicle_specifications.style}</h5>
            <h3><strong>Price:</strong> $${convertToFloat(vehicle.vehicle_settings.regular_price)}</h3>
            <p><strong>VIN:</strong> ${vehicle.vin}</p>
            <p><strong>Mileage:</strong> ${vehicle.mileage} KM</p>
            <p><strong>Engine:</strong> ${vehicle.vehicle_specifications.engine.size}L ${vehicle.vehicle_specifications.engine.cylinder} Cylinders</p>
            <p><strong>Transmission:</strong> ${vehicle.vehicle_specifications.transmission.style}</p>
            <p><strong>Drivetrain:</strong> ${vehicle.vehicle_specifications.drivetrain.drive_type}</p>
          </div>
        </div>
        <div class="description">
          <h3>Vehicle Description</h3>
          <p>
            ${vehicle.vehicle_description.html}
          </p>
        </div>
      </div>
    </body>
    </html>
  `;

  // Create a Blob from the HTML content
  const blob = new Blob([htmlContent], { type: 'text/html' });
  const url = URL.createObjectURL(blob);

  // Open the Blob in a new tab
  window.open(url, '_blank');

}

export { generateWindowSticker }