<script setup>
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs'
import { ref, computed } from 'vue'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const props = defineProps({
    id: String,
    dataset: Object,
})

const chartData = computed( () => {
    return {labels: ['Active', 'No Price', 'No Image', '> 15 Days', '15+ Days', '30+ Days', '60+ Days'],
    datasets: [
        {
            data: [props.dataset.total, props.dataset.no_price, props.dataset.no_img, props.dataset.lt_15, props.dataset.gte_15, props.dataset.gte_30, props.dataset.gte_60],
            label: 'No. of Vehicles',  
            backgroundColor: ['#4cb050', '#ffcc00', '#ffcc00', '#99cc33', '#99cc33', '#ffcc00', '#ff9a66'] }
        ],
   
}
 
})

const chartOptions = {
    responsive: true
}
</script>
<template>
    <Bar :id="id" :options="chartOptions" :data="chartData" />
</template>