<script setup>
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs'
import { ref, computed } from 'vue'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const props = defineProps({
    id: String,
    dataset: Object,
})

const chartData = computed(() => {
    return {
        labels: ['Open', 'Pending', 'Sold', 'Delivered', 'Lost', 'Total'],
        datasets: [
            {
                data: [props.dataset.open, props.dataset.pending, props.dataset.sold, props.dataset.delivered, props.dataset.lost, props.dataset.total],
                label: 'No. of Deals',
                backgroundColor: ['#2396ef', '#ffcc00', '#4db053', '#006055', '#bdbdbd', '#99cc33']
            }
        ],

    }

})

const chartOptions = {
    responsive: true,
}
</script>
<template>
    <Bar :id="id" :options="chartOptions" :data="chartData" />
</template>